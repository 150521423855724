<template>
  <header id="header" class="header d-flex align-items-center sticky-top">
    <div
      class="container position-relative d-flex align-items-center justify-content-between"
    >
      <a href="/" class="logo d-flex align-items-center me-auto me-xl-0">
        <h1 class="sitename">Tamil Data</h1>
        <span>.</span>
      </a>

      <nav id="navmenu" class="navmenu">
        <ul>
          <li><a :class="activeClass('home')" href="/">Home</a></li>
          <li><a :class="activeClass('about')" href="/about">About</a></li>
          <li>
            <a :class="activeClass('how-it-works')" href="/how-it-works"
              >How It Works</a
            >
          </li>
          <li>
            <a :class="activeClass('archive')" href="/archive">Archive</a>
          </li>
          <li><a :class="activeClass('team')" href="/team">Team</a></li>
          <li>
            <a :class="activeClass('workshop')" href="/workshop">Workshop</a>
          </li>
          <li>
            <a :class="activeClass('contact')" href="/contact">Contact</a>
          </li>
        </ul>
        <i class="mobile-nav-toggle d-xl-none bi bi-list"></i>
      </nav>

      <a
        class="btn-getstarted"
        href="https://s3.ap-southeast-1.amazonaws.com/static.tamiltoken.org/downloads/tamilcrypto-app-win32-x64.zip"
      >
        <i class="bi bi-cloud-download icon"></i> Download Client
      </a>
    </div>
  </header>
</template>

<script>
export default {
  name: "NavBar",
  methods: {
    activeClass: function (...names) {
      for (let name of names) {
        if (name == this.$route.name) return "active";
      }
    },
  },
};
</script>

<style scoped>
/* Add any necessary styles */
</style>
