<template>
  <div class="hello">
    <h1 class="text-style mt-3">{{ msg }}</h1>
    <p>Decentralizing Digital Assets of Tamil Language.</p>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.text-style {
  font-family: sans-serif !important;
  font-size: x-large;
}
</style>
